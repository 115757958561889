import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./data/store";
import App from "./App";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <GoogleOAuthProvider clientId="230046742923-vglbrln68c05b1hbpc19s0v34rehv76q.apps.googleusercontent.com">
    <Provider store={configureStore()}>
      <Router>
        <App />
      </Router>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
