export const API_HOST = "https://api.pledgx.com/api/v1";
//export const API_HOST = "http://127.0.0.1:5000/api/v1";
//export const API_HOST = "https://sit.api.pledgx.com/api/v1";
export const APP_URL = 'https://qualify.pledgx.com'
// export const APP_URL = "https://sit.qualify.pledgx.com";
// export const APP_URL = "http://localhost:3000";

// Test (Local/SIT)
// export const STRIPE_PUBLISHABLE_KEY =
//   "pk_test_51LBLgRAgrld4Di6O2URvGvMf0JTyjczHd1X2jjRwM1dl2khGlE0n83Gft1vk37ufo1NqZJFOQgBPVqgpvsRDtORN004N0rlaeO";
// Live (Production)
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51LBLgRAgrld4Di6OVDNr1i6wOkZRGIbjAimA6T9Q7ir0iHDz0PQDicuWe40qRKtIDTHEcqz8SAvHp7qZP3QZUj5a00UXcQcZCz'

export const COLORS = {
  primaryOrange: "#FF6D1D",
  secondaryOrange: "#FFAA7B",
  lighOrange: "#FFD9C5",
  borderOrange: "#FF692E",
  lightGrey1: "#C4C4C4",
  lightGrey2: "#B9B9C3",
  lightGrey3: "#D5D5D5",
  lightGrey4: "#ECEBED",
  darkGrey1: "#6E6B7B",
  darkGrey2: "#5E5873",
  darkGrey3: "#ECEBED",
  disabledGrey: "#B9B9C3",
  greyBorder: "#EBE9F1",
  textGrey: "#888888",
  blurOutText: "#D0C9D6",
  primaryGreen: "#43B868",
  primaryGreen2: "#ADEFC2",
  lightGreen4: "#43B868",
  primaryRed: "#FF0000",
  accordionFill: "#F8F8F8",
  accordionFill2: "#F8F8FA",
  scrollbarTrack: "#ECE9F1",
  black: "#000000",
  primaryBlue: "#0969F9",
  primaryYellow: "#FEC65C",
  primaryDarkPurple: "#1A051D",
  primaryLightOrange: "#FFA26B",
  primaryGray: "#B9B9C3",
  secondaryLightOrange: "#FFE8DA",
  backgroundGrey: "#F9FBFC",
  columnGreyText: "#6E6B7B",
  smallTitleGrey: "#D0C9D6",
  lightGreen: "#ADEFC2",
  lightBlue: "#ADD8E6",
  lightYellow: "#FFE6B6",
};

export const DASHBOARDHEADER_HEIGHT = 64;
export const SYSTEM_MESSAGES_HEIGHT = 64;

export const TYPOGRAPHY = {
  primaryFont: "SF Pro Text",
  secondaryFont: "Metropolis",
  displayFont: "SF Pro Display",
};

export const FONT_SIZES = {
  heading_1: "60px",
  heading_2: "50px",
  heading_3: "45px",
  heading_5: "37px",
  heading_6: "32px",
  body_1: "27px",
  body_2: "22px",
  body_3: "17px",
  body_4: "15px",
  body_5: "12.5px",
  body_6: "10px",
};

export const PROVINCES = [
  "NL",
  "PE",
  "NS",
  "NB",
  "QC",
  "ON",
  "MB",
  "SK",
  "AB",
  "BC",
  "YT",
  "NT",
  "NU",
];

// Return today's date and time
let currentTime = new Date();

// returns the year (four digits)
export const YEAR = currentTime.getFullYear();

export const MainOptions = [
  { key: 1, text: "Aggregates", value: "Aggregates" },
  {
    key: 2,
    text: "Bridge Matls,Tools & Equipment",
    value: "Bridge Matls,Tools & Equipment",
  },
  {
    key: 3,
    text: "Building Operation - Supplies and Equipment",
    value: "Building Operation - Supplies and Equipment",
  },
  { key: 4, text: "Building Services", value: "Building Services" },
  {
    key: 5,
    text: "Building Structures & Land",
    value: "Building Structures & Land",
  },
  { key: 6, text: "Construction", value: "Construction" },
  { key: 7, text: "Consulting Services", value: "Consulting Services" },
  { key: 8, text: "Contracted Services", value: "Contracted Services" },
  {
    key: 9,
    text: "Environmental Goods & Services",
    value: "Environmental Goods & Services",
  },
  {
    key: 10,
    text: "Facilities, Redevelopment, Trades, Machinery, Construction, Architectural or Engineering",
    value:
      "Facilities, Redevelopment, Trades, Machinery, Construction, Architectural or Engineering",
  },
  {
    key: 11,
    text: "Heavy Construction - Earth Handling Equipment (Industrial)",
    value: "Heavy Construction - Earth Handling Equipment (Industrial)",
  },
  { key: 12, text: "Landscaping & Forestry", value: "Landscaping & Forestry" },
  { key: 13, text: "Oil & Gas Operations", value: "Oil & Gas Operations" },
  { key: 14, text: "Parks and Trails", value: "Parks and Trails" },
  { key: 15, text: "Recreation/Education", value: "Recreation/Education" },
  {
    key: 16,
    text: "Roads - Maintenance, Services and Traffic",
    value: "Roads - Maintenance, Services and Traffic",
  },
  { key: 17, text: "Trade Services", value: "Trade Services" },
  { key: 18, text: "Water and Sewer", value: "Water and Sewer" },
];

export const Aggregates = [
  { key: 1, text: "Asphalt", value: "Asphalt" },
  { key: 2, text: "Concrete", value: "Concrete" },
  { key: 3, text: "Granular Materials", value: "Granular Materials" },
  { key: 4, text: "Sand", value: "Sand" },
  { key: 5, text: "Winter Salt", value: "Winter Salt" },
];

export const BuildingService = [
  { key: 1, text: "Building Automation", value: "Building Automation" },
  { key: 2, text: "Cleaning", value: "Cleaning" },
  { key: 3, text: "Elevator", value: "Elevator" },
  { key: 4, text: "Fencing", value: "Fencing" },
  { key: 5, text: "Generator Equipment", value: "Generator Equipment" },
  { key: 6, text: "Graffiti Removal", value: "Graffiti Removal" },
  { key: 7, text: "Industrial Cleaning", value: "Industrial Cleaning" },
  { key: 8, text: "Locksmith", value: "Locksmith" },
  { key: 9, text: "Mats & Linens", value: "Mats & Linens" },
  { key: 10, text: "Movers & Storage", value: "Movers & Storage" },
  { key: 11, text: "Overhead Door", value: "Overhead Door" },
  {
    key: 12,
    text: "Parking Lighting Maintenance",
    value: "Parking Lighting Maintenance",
  },
  { key: 13, text: "Pest Control", value: "Pest Control" },
  {
    key: 14,
    text: "Production & Manufacturing Services",
    value: "Production & Manufacturing Services",
  },
  { key: 15, text: "Security & Monitoring", value: "Security & Monitoring" },
  { key: 16, text: "Sprinkler System", value: "Sprinkler System" },
  { key: 17, text: "Window Cleaning", value: "Window Cleaning" },
];

export const BuildingOperation = [
  { key: 1, text: "Doors/Windows/Locks", value: "Doors/Windows/Locks" },
  { key: 2, text: "Window Coverings", value: "Window Coverings" },
  {
    key: 3,
    text: "Building Operation Supplies and Equipment",
    value: "Building Operation Supplies and Equipment",
  },
  { key: 4, text: "Construction", value: "Construction" },
  { key: 5, text: "Consulting Services", value: "Consulting Services" },
  { key: 6, text: "Electrical", value: "Electrical" },
];

export const Environment = [
  { key: 1, text: "Green Power", value: "Green Power" },
];

export const Parks = [
  { key: 1, text: "Concessions", value: "Concessions" },
  {
    key: 2,
    text: "Engineered Wood Fiber Surface",
    value: "Engineered Wood Fiber Surface",
  },
  { key: 3, text: "Equipment & Supplies", value: "Equipment & Supplies" },
  { key: 4, text: "Facility Management", value: "Facility Management" },
];

export const RecreationEducation = [
  { key: 1, text: "Bleachers/Stands ", value: "Bleachers/Stands" },
  {
    key: 2,
    text: "Court Surface Specialists",
    value: "Court Surface Specialists",
  },
  {
    key: 3,
    text: " Paint (Field marking, ice paint, etc.)",
    value: " Paint (Field marking, ice paint, etc.)",
  },
  { key: 4, text: "Park Development", value: "Park Development" },
  { key: 5, text: "Playground Equipment", value: "Playground Equipment" },
  { key: 6, text: "Pool Services", value: "Pool Services" },
  { key: 7, text: "Pool Supplies", value: "Pool Supplies" },
  { key: 8, text: "Sports Field Lighting", value: "Sports Field Lighting" },
  {
    key: 9,
    text: "Welding Equipment & Supplies",
    value: "Welding Equipment & Supplies",
  },
  { key: 10, text: "Arena Supplies", value: "Arena Supplies" },
  { key: 11, text: "Fitness Equipment", value: "Fitness Equipment" },
  { key: 12, text: "Ice Resurfacer", value: "Ice Resurfacer" },
  {
    key: 13,
    text: "Recreation & Sporting Good Supplies (Toys, Games, etc.)",
    value: "Recreation & Sporting Good Supplies (Toys, Games, etc.)",
  },
  {
    key: 14,
    text: "Recreation Flooring / Restoration (Gym Floor, Rubberized, etc.)  ",
    value: "Recreation Flooring / Restoration (Gym Floor, Rubberized, etc.) ",
  },
];

export const Construction = [
  { key: 1, text: "Affordable Housing", value: "Affordable Housing" },
  { key: 2, text: "Building & Renovation", value: "Building & Renovation" },
  { key: 3, text: "Demolition", value: "Demolition" },
  { key: 4, text: "Drainage", value: "Drainage" },
  { key: 5, text: "Heavy Duty Equipment", value: "Heavy Duty Equipment" },
  { key: 6, text: "Infrastructure", value: "Infrastructure" },
  {
    key: 7,
    text: "Infrastructure (Traffic Signalization)",
    value: "Infrastructure (Traffic Signalization)",
  },
  {
    key: 8,
    text: "Infrastructure (Wastewater)",
    value: "Infrastructure (Wastewater)",
  },
  { key: 9, text: "Municipal Drains", value: "Municipal Drains" },
  { key: 10, text: "Oil & Gas Pipe Construction", value: "Oil & Gas Pipe Construction" },
  { key: 11, text: "Parks, Trails & Landscape", value: "Parks, Trails & Landscape" },
  { key: 12, text: "Power Lines", value: "Power Lines" },
  { key: 13, text: "Remediation Services", value: "Remediation Services" },
  { key: 14, text: "Restoration Services", value: "Restoration Services" },
  {
    key: 15,
    text: "Roads, Bridge, Sidewalks",
    value: "Roads, Bridge, Sidewalks",
  },
  {
    key: 16,
    text: "Supplies (barricades, pylons, speed signs, etc.)",
    value: "Supplies (barricades, pylons, speed signs, etc.)",
  },
  { key: 17, text: "Utility", value: "Utility" },
  { key: 18, text: "Services", value: "Services" },
  { key: 19, text: "Supplies", value: "Supplies" },
];

export const Consulting = [
  { key: 1, text: "Asbestos removal", value: "Asbestos removal" },
  { key: 2, text: "Roofing", value: "Roofing" },
];

export const WaterSewer = [
  { key: 1, text: "Catch Basin Cleaning", value: "Catch Basin Cleaning" },
  { key: 2, text: "Cathodic Protection", value: "Cathodic Protection" },
  { key: 3, text: "CCTV Inspection", value: "CCTV Inspection" },
  { key: 4, text: "Emergency Watermain", value: "Emergency Watermain" },
  { key: 5, text: "Flow Testing", value: "Flow Testing" },
  { key: 6, text: "Hydrant Inspections", value: "Hydrant Inspections" },
  { key: 7, text: "Hydrant Painting", value: "Hydrant Painting" },
  {
    key: 8,
    text: "New or Replacement Water/Sewer Lines",
    value: "New or Replacement Water/Sewer Lines",
  },
  {
    key: 9,
    text: "Wastewater Treatment & Services",
    value: "Wastewater Treatment & Services",
  },
  { key: 10, text: "Water Meters", value: "Water Meters" },
  {
    key: 11,
    text: "Water Treatment Chemicals Supplies & Services",
    value: "Water Treatment Chemicals Supplies & Services",
  },
  { key: 12, text: "Watermain Swabbing", value: "Watermain Swabbing" },
  {
    key: 13,
    text: "Well & Water Related Services",
    value: "Well & Water Related Services",
  },
];

export const BuildingStructuresLand = [
  { key: 1, text: "Land", value: "Land" },
  { key: 2, text: "Permanent Buildings", value: "Permanent Buildings" },
  { key: 3, text: "Portable Buildings", value: "Portable Buildings" },
  { key: 4, text: "Prefabricated", value: "Prefabricated" },
  { key: 5, text: "Thoroughfares", value: "Thoroughfares" },
];

export const LandscapingForestry = [
  { key: 1, text: "Arborist", value: "Arborist" },
  { key: 2, text: "Arborist Consultation", value: "Arborist Consultation" },
  { key: 3, text: "Artificial Turf", value: "Artificial Turf" },
  { key: 4, text: "Bedding Plants", value: "Bedding Plants" },
  { key: 5, text: "Fertilizer", value: "Fertilizer" },
  { key: 6, text: "Field Restoration", value: "Field Restoration" },
  { key: 7, text: "Grass Cutting", value: "Grass Cutting" },
  { key: 8, text: "Horticultural Supplies", value: "Horticultural Supplies" },
  {
    key: 9,
    text: "Interlocking Brick Maintenance",
    value: "Interlocking Brick Maintenance",
  },
  {
    key: 10,
    text: "Landscape & Forestry Supplies",
    value: "Landscape & Forestry Supplies",
  },
  { key: 11, text: "Mulch", value: "Mulch" },
  { key: 12, text: "Property Maintenance", value: "Property Maintenance" },
  { key: 13, text: "Seeding", value: "Seeding" },
  {
    key: 14,
    text: "Small Landscape Equipment (ex. Blowers, push mowers, weed wackers, etc.)",
    value:
      "Small Landscape Equipment (ex. Blowers, push mowers, weed wackers, etc.)",
  },
  { key: 15, text: "Snow Removal", value: "Snow Removal" },
  { key: 16, text: "Sod", value: "Sod" },
  { key: 17, text: "Supplies & Services", value: "Supplies & Services" },
  { key: 18, text: "Top Soil", value: "Top Soil" },
  { key: 19, text: "Tree Maintenance", value: "Tree Maintenance" },
  { key: 20, text: "Tree planting", value: "Tree planting" },
  { key: 21, text: "Turf Maintenance", value: "Turf Maintenance" },
];

export const OilGasOperations = [
  { key: 1, text: "Coil Tubing", value: "Coil Tubing" },
  { key: 2, text: "Downhole Tool Equipment", value: "Downhole Tool Equipment" },
  {
    key: 3,
    text: "Drilling and Completions Equipment",
    value: "Drilling and Completions Equipment",
  },
  { key: 4, text: "Field Services", value: "Field Services" },
  {
    key: 5,
    text: "Fluid Hauling and Trucking",
    value: "Fluid Hauling and Trucking",
  },
  { key: 6, text: "Inspection Services", value: "Inspection Services" },
  { key: 7, text: "Mining Services", value: "Mining Services" },
  { key: 8, text: "Oilfield Rental", value: "Oilfield Rental" },
  {
    key: 9,
    text: "Vacuum/Pressure Truck Services",
    value: "Vacuum/Pressure Truck Services",
  },
];

export const RoadsMaintenanceServicesTraffic = [
  {
    key: 1,
    text: "Roadside Safety (Pylons, Barricades, etc.)",
    value: "Roadside Safety (Pylons, Barricades, etc.)",
  },
  {
    key: 2,
    text: "Services (crack sealing, pavement markings, etc.)",
    value: "Services (crack sealing, pavement markings, etc.)",
  },
  { key: 3, text: "Street Cleaning", value: "Street Cleaning" },
  {
    key: 4,
    text: "Streetlight supply & maintenance",
    value: "Streetlight supply & maintenance",
  },
  { key: 5, text: "Traffic Counting", value: "Traffic Counting" },
  { key: 6, text: "Traffic Marking Paint", value: "Traffic Marking Paint" },
];

export const TradeServices = [
  { key: 1, text: "Carpentry", value: "Carpentry" },
  { key: 2, text: "Carpet/ Flooring", value: "Carpet/ Flooring" },
  { key: 3, text: "Glass and Glazing", value: "Glass and Glazing" },
  {
    key: 4,
    text: "Heating, Ventilating and Air Conditioning (HVAC)",
    value: "Heating, Ventilating and Air Conditioning (HVAC)",
  },
  { key: 5, text: "Insulation", value: "Insulation" },
  {
    key: 6,
    text: "Painting and Wallpapering",
    value: "Painting and Wallpapering",
  },
  { key: 7, text: "Plastering Drywall", value: "Plastering Drywall" },
  { key: 8, text: "Roofing and Siding", value: "Roofing and Siding" },
  {
    key: 9,
    text: "Tile and Marble Work, All Types",
    value: "Tile and Marble Work, All Types",
  },
  {
    key: 10,
    text: "Trade Services, (Not Otherwise Classified)",
    value: "Trade Services, (Not Otherwise Classified)",
  },
];

export const Classification = [
  { key: 1, text: "Class-1", value: "1" },
  { key: 2, text: "Class-2", value: "2" },
  { key: 3, text: "Class-3", value: "3" },
  { key: 4, text: "Class-4", value: "4" },
  { key: 5, text: "Class-5", value: "5" },
];

export const Roles = [
  { key: 1, text: "Admin", value: "admin" },
  { key: 2, text: "User", value: "user" },
];

export const FacilityType = ["Overdraft", "Loan", "Letters of Credit"];
export const CurrencyType = [
  { key: 1, text: "CAD", value: "CAD" },
  { key: 2, text: "USD", value: "USD" },
];
export const InsuranceType = [
  "General Liability Insurance",
  "Automobile Liability Insurance",
  "Broad Form Contractor’s Equipment",
];

export const healthSafetyProgramList = [
  "Alcohol & Drug Program",
  "Company Rules",
  "Confined Spaces",
  "Defective Tools",
  "Electrical Safety",
  "Emergency Procedures",
  "Environmental",
  "Fall Protection Plan",
  "Fire Protection",
  "Hand Tools",
  "Hazard Reporting",
  "Injury Reporting",
  "Ladder Safety",
  "Life Saving Procedures",
  "Modified Work Program",
  "Personal Protective Equipment",
  "Preventative Maintenance",
  "Progressive Discipline",
  "Rigging & Crane Safety",
  "Rights & Responsibilities",
  "Safe Limits of Approach",
  "Signs and Barricades",
  "Trenching & Excavation",
  "Vehicle Safety",
  "Violence & Harassment",
];

export const healthSafetyEmployeeOrientationProgramList = [
  "Corporate HSE Policy",
  "Emergency Procedures",
  "Environmental Procedures",
  "Equipment and Maintenance",
  "Hazard Assessment Process",
  "Hazard Communication Program",
  "Incident Reporting & Management",
  "Joint Health & Safety Policy",
  "Other",
  "Pandemic Policy",
  "Personal Protectie Equipment Policy",
  "Project Specific Safety Orientation Plan",
  "Respiratory Plan",
  "Return to Work Program",
  "Safety Audits, Inspections & Meetings Policy",
  "Safety Rules and Procedures",
  "Silica Prevention Plan",
  "Statistics and Records Policy",
  "Substance Abuse Program",
  "Vehicle Operations",
  "Violence & Harassment Policy",
  "Waste Management",
];
